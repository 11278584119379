import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ pageName, pageMeta, noindex }) => {
  const { site } = useStaticQuery(graphql`
    query HeadingQuery {
      site: site {
        siteMetadata {
          title
          description
          image
          siteUrl
          noindex
        }
      }
      settings: storyblokEntry(slug: { eq: "settings" }) {
        id
        content
      }
    }
  `);
  const titleTemplate = (props) => `${props} | ${site.siteMetadata.title}`;

  return (
    <Helmet>
      <title>{pageMeta?.title || titleTemplate(pageName)}</title>
      <meta
        name="description"
        content={pageMeta?.description || site.siteMetadata.description}
      />
      <meta name="twitter:card" content="summary_large_image" />
      {pageName === "CX Insights Hub" ? (
        <meta
          name="image"
          content={
            "https://a.storyblok.com/f/131443/1440x664/2abf1ee2aa/cx_insights_hub.png"
          }
        />
      ) : (
        <meta
          name="image"
          content={pageMeta?.og_image || site.siteMetadata.image}
        />
      )}
      <meta
        property="og:title"
        content={
          pageMeta?.og_title
            ? pageMeta?.og_title
            : pageMeta?.title
            ? pageMeta?.title
            : titleTemplate(pageName)
        }
      />
      <meta name="robots" content={noindex || site.siteMetadata?.noindex} />
      <meta
        property="og:description"
        content={
          pageMeta?.og_description
            ? pageMeta?.og_description
            : pageMeta?.description
            ? pageMeta?.description
            : site.siteMetadata.description
        }
      />
      {pageName === "CX Insights Hub" ? (
        <meta
          name="og:image"
          content={
            "https://a.storyblok.com/f/131443/1440x664/2abf1ee2aa/cx_insights_hub.png"
          }
        />
      ) : (
        <meta
          property="og:image"
          content={pageMeta?.og_image || site.siteMetadata.image}
        />
      )}
      <meta property="og:type" content="website" />
      <meta
        name="twitter:title"
        content={
          pageMeta?.twitter_title
            ? pageMeta?.twitter_title
            : pageMeta?.title
            ? pageMeta?.title
            : titleTemplate(pageName)
        }
      />
      <meta
        name="twitter:description"
        content={
          pageMeta?.twitter_description
            ? pageMeta?.twitter_description
            : pageMeta?.description
            ? pageMeta?.description
            : site.siteMetadata.description
        }
      />
      {pageName === "CX Insights Hub" ? (
        <meta
          name="twitter:image"
          content={
            "https://a.storyblok.com/f/131443/1440x664/2abf1ee2aa/cx_insights_hub.png"
          }
        />
      ) : (
        <meta
          name="twitter:image"
          content={pageMeta?.twitter_image || site.siteMetadata.image}
        />
      )}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          name: site.siteMetadata.title,
          url: site.siteMetadata.siteUrl,
          logo: site.siteMetadata.image,
        })}
      </script>
      {JSON.stringify(pageMeta)}
    </Helmet>
  );
};
export default Seo;
