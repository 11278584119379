import React from 'react'

const ContentLabel = ({ type }) => {
    switch(type) {
        case "book_review" :
            return <p className="text-xs italic bg-[#FBFBFB] text-darkgrey pl-7 py-1 pr-3 before:bg-red before:w-3 before:h-1 before:absolute before:top-1/2 before:left-2 before:transform before:-translate-y-1/2">Book Review</p>
        case "news_and_opinion" :
            return <p className="text-xs italic bg-[#FBFBFB] text-darkgrey pl-7 py-1 pr-3 before:bg-red before:w-3 before:h-1 before:absolute before:top-1/2 before:left-2 before:transform before:-translate-y-1/2">News &amp; Opinion</p>
        case "podcast" :
            return <p className="text-xs italic bg-[#FBFBFB] text-darkgrey pl-7 py-1 pr-3 before:bg-mintgreen before:w-3 before:h-1 before:absolute before:top-1/2 before:left-2 before:transform before:-translate-y-1/2">Podcast</p>
        case "webinar" :
            return <p className="text-xs italic bg-[#FBFBFB] text-darkgrey pl-7 py-1 pr-3 before:bg-honey before:w-3 before:h-1 before:absolute before:top-1/2 before:left-2 before:transform before:-translate-y-1/2">Webinar</p>
        case "event" :
            return <p className="text-xs italic bg-[#FBFBFB] text-darkgrey pl-7 py-1 pr-3 before:bg-burntorange before:w-3 before:h-1 before:absolute before:top-1/2 before:left-2 before:transform before:-translate-y-1/2">Event</p>
        case "downloads" :
            return <p className="text-xs italic bg-[#FBFBFB] text-darkgrey pl-7 py-1 pr-3 before:bg-steelblue before:w-3 before:h-1 before:absolute before:top-1/2 before:left-2 before:transform before:-translate-y-1/2">Downloads</p>
        case "training_course" :
            return <p className="text-xs italic bg-[#FBFBFB] text-darkgrey pl-7 py-1 pr-3 before:bg-skyblue before:w-3 before:h-1 before:absolute before:top-1/2 before:left-2 before:transform before:-translate-y-1/2">Training Course</p>
        case "magazine" :
            return <p className="text-xs italic bg-[#FBFBFB] text-darkgrey pl-7 py-1 pr-3 before:bg-darkgrey before:w-3 before:h-1 before:absolute before:top-1/2 before:left-2 before:transform before:-translate-y-1/2">Magazine</p>
        default:
            return ''
    }
}

export default ContentLabel
