import React from "react";
import Archive from "../components/Archives/Archive";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Seo from "src/utils/seo";

const UniversalArchive = (props) => {
  let content = JSON.parse(props.data.settings.edges[0].node.content);
  let parsedSettings = Object.assign({}, content, { content: content });
  let getDescription;

  // Loop through and get the correct description
  for (const key in parsedSettings) {
    if (key === props.pageContext.archive_description_field) {
      getDescription = parsedSettings[key];
    }
  }

  return (
    <Layout location={props.location}>
      <Seo pageName={props.pageContext.title} />
      <Archive
        posts={props.data.posts.edges}
        numPages={props.pageContext.numPages}
        currentPage={props.pageContext.currentPage}
        title={props.pageContext.title}
        description={getDescription}
        pagination_slug={props.pageContext.slug}
        cta_split_with_image={{
          background_colour: parsedSettings.background_colour,
          image: parsedSettings.image,
          title: parsedSettings.title,
          content: parsedSettings.content.content,
          button_target: parsedSettings.button_target,
          button_colour: parsedSettings.button_colour,
          button_label: parsedSettings.button_label,
        }}
      />
    </Layout>
  );
};

export default UniversalArchive;

export const query = graphql`
  query getAllBlogPosts($skip: Int!, $limit: Int!, $field_component: String!) {
    posts: allStoryblokEntry(
      sort: { order: DESC, fields: first_published_at }
      limit: $limit
      skip: $skip
      filter: { field_component: { eq: $field_component } }
    ) {
      edges {
        node {
          id
          uuid
          name
          slug
          full_slug
          content
          created_at
          first_published_at
          field_component
        }
      }
    }
    settings: allStoryblokEntry(
      filter: { field_component: { eq: "settings" } }
    ) {
      edges {
        node {
          content
        }
      }
    }
  }
`;
