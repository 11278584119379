import React from 'react'
import Heading from 'src/components/Heading'
import RichText from 'src/utils/RichText'
import FluidImage from 'src/utils/FluidImage'
import ContentLabel from 'src/components/ContentLabel'
import DateAndTime from 'src/utils/DateAndTime'
import { Link } from 'gatsby'
import resolveLink from '../../utils/resolveLink'
import InsightsBadge from "src/images/insights-logo.svg"
import ShortenText from 'src/utils/ShortenText'

const ArchiveItem = (props) => {
    const data = JSON.parse(props.content)

    // So we don't make the code messy down the line
    const bodyFont = props.field_component === 'magazine' ? 'font-insights-body' : 'font-body'
    const displayFont = props.field_component === 'magazine' ? 'font-insights-display' : 'font-display'

    return (
        <Link className="group flex flex-col relative" key={props._uid} to={resolveLink(`/${props.full_slug}`)}>
            <div className="absolute left-0 top-0 z-10">
                <ContentLabel type={props.field_component} />
            </div>
            <div className="relative ">
                <FluidImage className="mb-3" image={data.thumbnail_image} />
                {props.field_component === 'magazine' && <InsightsBadge className="h-12 absolute bottom-2.5 left-2.5"/>}
            </div>
            <time className="font-body text-[0.6875em] text-[#2E2D2C]">
                {data.start_time && data.end_time && DateAndTime(data.start_time, data.end_time)}
            </time>
            <span className="font-body text-[0.6875em] text-[#2E2D2C]">{data.price}{data.type ? ` - ${data.type}` : ''}</span>
            <Heading size="h4" className={`text-darkgrey mt-1 mb-1 ${displayFont}`}>{props.name}</Heading>
            <p className={`text-xs text-darkgrey prose ${bodyFont}`}>
                <RichText>{ShortenText(data.meta.description)}</RichText>
            </p>
        </Link>
    )
};

export default ArchiveItem