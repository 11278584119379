import React from "react"
import { Link } from "gatsby"
import resolveLink from "src/utils/resolveLink"
import ArchiveItem from './ArchiveItem'
import SlimlineHero from 'src/components/TBB/Heros/SlimlineHero'
import CtaSplitWithImage from "src/components/Marketing/PageSections/CTASections/CtaSplitWithImage";

const Archive = ({ posts, numPages, currentPage, description, title, pagination_slug, cta_split_with_image }) => {

  return (
    <>
        <SlimlineHero 
            blok={{
                title: title,
                colour: 'darkgrey',
                description: description,
            }}
        />
        <section className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
            <div className="relative mx-auto max-w-7xl">
                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {posts.map((item, index) => (
                        <ArchiveItem {...item.node} key={index}/>
                    ))}
                </div>
            </div>
        </section>
        {numPages > 1 &&
            <nav aria-label="Page navigation" className="py-12">
                <ul className="text-center space-x-2">
                    {Array.from({ length: numPages }, (_, i) => (
                        <li className="inline-block" key={i}>
                            <Link
                                className={`flex justify-center items-center hover:text-red duration-300 ${
                                currentPage === i + 1
                                    ? "text-red border-t-2 border-red"
                                    : "text-darkblue"
                                } text-base font-display p-4`}
                                key={`pagination-number${i + 1}`}
                                to={resolveLink(`/${pagination_slug}/${i === 0 ? "" : i + 1 + "/"}`)}
                            >
                                <span>{i + 1}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>    
        }
        <CtaSplitWithImage
            blok={{
                background_colour: cta_split_with_image.background_colour,
                image: cta_split_with_image.image,
                title: cta_split_with_image.title,
                content: cta_split_with_image.content,
                button_target: cta_split_with_image.button_target,
                button_colour: cta_split_with_image.button_colour,
                button_label: cta_split_with_image.button_label,
            }}
        />
    </>
  )
}

export default Archive